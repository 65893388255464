<template>
  <div>
    <essential-informations
      :subscription-plan="subscriptionPlan"
      :validation="validation"
      :is-disabled="isDisabled"
    />
    <validity-informations
      :subscription-plan="subscriptionPlan"
      :validator="validator"
      :validation="validation"
      :violations="violations"
      :is-disabled="isDisabled"
    />
  </div>
</template>
<script>
export default {
  props: {
    subscriptionPlan: {
      type: Object,
      default: () => {
      },
    },
    validator: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    violations: {
      type: Array,
      default: () => []
    },
  },
  components: {
    EssentialInformations: () => import('./EssentialInformations'),
    ValidityInformations: () => import('./ValidityInformations')
  },
  data: () => ({
    validation: require('@validation/entities/doinsport/SubscriptionPlan.json')
  }),
  beforeMount() {
    this.$store.dispatch('validator/set', this.validation);
  }
}
</script>
<style scoped>

</style>
